<template>
  <div
    class="ma-0 pa-0 ml-5 d-flex justify-center align-center justify-center"
    style="width: 100vw; height: 100vh"
  >
    <v-card width="80vw" elevation="0" class="d-flex align-center mb-15">
      <v-container fluid grid-list-md>
        <v-layout row wrap d-flex justify-center>
          <v-flex d-flex justify-start xs12 sm12 md3>
            <div
              class="d-flex flex-column align-start mt-5"
              style="width: 100%"
            >
              <p class="display-2 font-weight-bold" style="color: #6e80f8">
                Ooops!
              </p>
              <p
                class="ma-0 pa-0 body-1 font-weight-bold"
                style="color: #4d4f5c"
              >
                No hemos podido encontrar
              </p>
              <p
                class="ma-0 pa-0 body-1 font-weight-bold"
                style="color: #4d4f5c"
              >
                la página que buscas
              </p>
              <p class="caption font-weight-bold mt-5" style="color: #a4afc0">
                Código de error: 404
              </p>
              <div class="d-flex align-center">
                <p
                  class="caption font-weight-bold ma-0 pa-0"
                  style="color: #a3aeff"
                >
                  Regresar
                </p>
                <v-btn
                  color="blue"
                  fab
                  x-small
                  dark
                  elevation="0"
                  class="ml-4"
                  @click="close"
                  style="
                    background: transparent linear-gradient(127deg, #8739eb 0%, #2871fa 100%) 0% 0% no-repeat padding-box"
                >
                  <v-icon small>mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-flex>
          <v-flex d-flex justify-start xs12 sm12 md3>
            <div style="width: 100%">
              <v-img src="../../assets/elements/robot.png" />
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import ReturnPage from "../../mixins/ReturnPage";
export default {
  mixins: [ReturnPage],
};
</script>
